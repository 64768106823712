@import "../mixins/media";
@import "../mixins/projects";

/*
注文履歴一覧

マイページ 注文履歴部に関する Project コンポーネントを定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole

Styleguide 16.1
*/
.ec-historyRole{
  & &__contents{
    padding-top: 1em;
    padding-bottom: 16px;
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    color: #525263;
    @include media_desktop {
      flex-direction: row;
    }
  }
  & &__header{
    width: 100%;
    @include media_desktop {
      width: 33.3333%;
    }
  }
  & &__detail{
    @include borderTop;
    width: 100%;

    .ec-imageGrid:nth-of-type(1) {
      border-top: none;
    }

    .ec-historyRole__detailTitle {
      margin-bottom: 8px;
      font-size: 1.6rem;
      font-weight: bold;
    }

    .ec-historyRole__detailPrice {
      margin-bottom: 8px;
      font-size: 1.6rem;
      font-weight: bold;
    }

    @include media_desktop {
      width: 66.6666%;
      border-top: none;
    }
  }
}

/*
注文履歴一覧 規格

マイページ 注文履歴内アイテムの規格を定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole-option

Styleguide 16.1.1
*/

.ec-historyRole{
  & &__detail {
    .ec-historyRole__detailOption {
      display: inline-block;
      margin-bottom: 8px;
      margin-right: .5rem;
      font-size: 1.6rem;
    }
    .ec-historyRole__detailOption::after {
      display: inline-block;
      padding-left: .5rem;
      content: "/";
      font-weight: bold;
    }
  }
}

/*
注文履歴一覧ヘッダ

注文履歴一覧で使用するヘッダのコンポーネントを定義します。

ex [マイページ　注文履歴一覧ヘッダ](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyHeader
p hofe

Styleguide 16.1.2
*/


.ec-historyListHeader{
  & &__date{
    font-weight: bold;
    font-size: 16px;
    @include media_desktop {
      font-weight: bold;
      font-size: 20px;
    }
  }
  & &__action{
    margin : 16px 0;
    a {
      font-size: 12px;
      font-weight: normal;
      @include media_desktop {
        font-size: 14px;
      }
    }
  }
}

.sr-historySearch__header{
  form {
    color: #525263;
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    @include media_desktop {
      flex-direction: row;
    }
    dl {
      display: block;
      margin: 5px 0;
      dt {
        width: 100px;
        @include media_desktop {
          display: inline-block;
        }
        legend {
          border: 0;
        }
      }
      dd {
        display: inline-block;
        > div {
          margin: 0;
          display: inline-block;
        }
        > .ec-select {
          width: 40%;
        }
        .ec-checkbox{
          .form-check {
            display: inline;
          }
          .form-check-input {
            margin-right: 10px;
          }
          .form-check-label {
            font-size: 14px;
            margin-right: 20px; 
          }
        }
      }
    }
    .ec-historySearch__header_left {
      width: 100%;
      @include media_desktop {
        display: inline-block;
        width: 60%;
      }
    }
    .ec-historySearch__header_right {
      width: 100%;
      @include media_desktop {
        display: inline-block;
        width: 35%;
      }
    }
  }
}

.sr-historySearch__body__control {
  display: inline-block;
  padding: 14px 0;
  text-align: right;
  width: 100%;
  .ec-inlineBtn {
    transition: opacity 1s;
  }
}

.sr-historySearch__body__result__block {
  border-top: 1px solid #ccc;
  color: #666;
  .sr-historySearch__body__result {
    padding: 14px 0;
    @include media_desktop{
      display: flex;
    }
    .sr-historySearch__body__item {
      display: block;
      width: 100%;
      @include media_desktop{
        display: inline-block;
        width: 24%;
      }
    }
    .sr-historySearch__body__control {
      padding: 0;
      @include media_desktop{
        width: 24%;
      }
    }
    .ec-inlineBtn {
      margin: 8px 0;
      transition: opacity 1s;
    }
  }
}

.sr-historySearch__payment {
  width: 100%;
  h2 {
    font-size: 14px;
    font-weight: bold;
  }
  @include media_desktop {
    width: 33%;
    margin-left: auto;
  }
  .sr-historySearch__control {
    div {
      margin-left: auto;
      input {
        text-align: end;
      }
    }
  }
  p {
    text-align: left;
  }
  button {
    width: 100%;
    transition: opacity 1s;
  }
}