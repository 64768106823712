@import "../mixins/media";
@import "../mixins/projects";
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/
.ec-shelfRole{
  @include container;
}

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid{
  @include reset_link;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;

  @include media_desktop {
    margin-left: -16px;
    margin-right: -16px;
  }
  & &__item{
    margin-bottom: 36px;
    margin-right: 1%;
    width: 32%;
    max-width: 108px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    p {
      margin-bottom: 0;
      overflow: hidden;
      position: relative;
    }
    &-image {
      margin-bottom: 10px;
      text-align: center;
      img {
        height: 160px;
        @include media_desktop() {
          height: 200px;
        }
      }
    }
    a.sr-btn:hover {
      opacity: 0.6;
    }
    a.sr-btn {
      transition: opacity 1s;
    }
    a {
      height: 160px;
      @include media_desktop {
        height: 200px;
      }
    }
    .sr-label_ladies {
      background-color:#FAD1BB;
      color: #fff;
      text-align: center;
      width: 100%;
    }
    .sr-label_mens {
      background-color:#9ECBC1;
      color: #fff;
      text-align: center;
      width: 100%;
    }
    .sr-label_kids {
      background-color:#FCEBAE;
      color: #fff;
      text-align: center;
      width: 100%;
    }
    .sr-label_brand {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .sr-label_osaka_shop {
      background-color: #686868;
      border-radius: 3px;
      color: #fff;
      display: inline-block;
      margin: 2px;
      padding: 0 5px;
    }
    .sr-label_plus_shop {
      background-color: #ff9999;
      border-radius: 3px;
      color: #fff;
      display: inline-block;
      margin: 2px;
      padding: 0 5px;
    }
    .sr-label_unused {
      background-color: #fff;
      border-radius: 3px;
      border: solid 2px;
      color: #84cbfa;
      display: inline-block;
      margin: 2px;
      padding: 0 3px;
      line-height: 1.4;
    }
    .sr-label_zaiko_status {
      background-color: #7f7fff;
      border-radius: 3px;
      color: #fff;
      display: inline-block;
      margin: 2px;
      padding: 0 5px;
    }
    .sr-label_variation {
      background-color: #7fcf7f;
      border-radius: 3px;
      color: #fff;
      display: inline-block;
      margin: 2px;
      padding: 0 5px;
    }
    .sr-label_product_name {
      margin: 10px 0 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .sr-label_product_code {
      margin-bottom: 0px;
    }
    @include media_desktop(){
      font-size: 14px;
      margin-right: 2%;
      padding: 0;
      width: 18%;
      max-width: 134px;
    }

    .ec-productRole__btn {
      margin-top: auto;
      margin-bottom: 15px;
    }
  }
  & &__title {
    margin-bottom: 7px;
  }
  & &__plice {
    font-weight: bold;
  }
}

/*
13.2.2 商品一覧グリッド（中央寄せ）

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。
商品のあまりはセンタリングされ、中央に表示されます。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGridCenter

Styleguide 13.2.2

*/
.ec-shelfGridCenter{
  @include reset_link;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center;

  @include media_desktop {
    margin-left: -16px;
    margin-right: -16px;
  }
  & &__item{
    margin-bottom: 36px;
    width: 50%;
    &-image {
      height: 150px;
      margin-bottom: 10px;
      text-align: center;
      @include media_desktop() {
        height: 250px;
      }
    }
    img{
      width: auto;
      max-height: 100%;
    }
    @include media_desktop(){
      padding: 0 16px;
      width: 25%;
    }

    .ec-productRole__btn {
      margin-top: auto;
      padding-top: 1em;
    }
  }
  & &__item:nth-child(odd){
    padding-right: 8px;
    @include media_desktop(){
      padding: 0 16px;
    }
  }
  & &__item:nth-child(even){
    padding-left: 8px;
    @include media_desktop(){
      padding: 0 16px;
    }
  }
  & &__title {
    margin-bottom: 7px;
  }
  & &__plice {
    font-weight: bold;
  }
}
