@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  @include container;
  dl, p {
    margin-bottom: 0;
  }
  & &__img {
    margin-right: 0;
    margin-bottom: 20px;
    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  & &__profile {
    margin-left: 0;
    @include media_desktop {
      margin-left: 16px;
    }
    fieldset {
      display: none;
    }
  }
  & &__title {
    margin: 20px 0;
    .ec-headingTitle {
      font-size: 20px;
      @include media_desktop {
        font-size: 32px;
      }
    }
  }
  & &__tags {
    margin-top: 16px;
    padding: 0;
    padding-bottom: 16px;
    border-bottom: 1px dotted #ccc;
  }
  & &__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 80%;
    color: #525263;
    border: solid 1px #D7DADD;
    border-radius: 3px;
    background-color: #F5F7F8;
  }
  & &__priceRegular {
    padding-top: 14px
  }
  & &__priceRegularTax {
    margin-left: 5px;
    font-size: 10px;
  }
  & &__price {
    font-size: 25px;
    padding: 0;
    border-bottom: 0;
  }
  & &__code {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc;
  }
  & &__category {
    padding: 14px 0;
    a {
      color: #333;
      text-decoration: underline;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    dl dd {
      line-height: 1.6;
    }
  }
  & &__actions {
    padding: 14px 0;
    .ec-select {
      select {
        height: 40px;
        max-width: 100%;
        min-width: 100%;
        @include media_desktop {
          min-width: 350px;
          max-width: 350px;
        }
      }
    }
  }
  .sr-additional_product_area {
    .ec-productRole__btn {
      button {
        font-size: 8px;
        padding: 0;
        white-space: normal;
        @include media_desktop {
          font-size: 14px;
          padding: 10px;
        }
      }
    }
  }
  & &__btn {
    margin-bottom: 10px;
    @include media_desktop {
      width: 60%;
      margin-top: 16px;
      margin-bottom: 16px;
      width: 100%;
    }
    button {
      color: #fff;
      display: block;
      height: 42px;
      line-height: 100%;
      padding: 10px;
      text-align: center;
      width: 100%;
    }
  }
  & &__description {
    margin-bottom: 16px;
  }
  .ec-price__heading {
    font-size: 14px;
    font-weight: normal;
  }
  .sr-notice_plus a {
    color: #666;
  }
  .sr-notice_plus a:hover {
    @include media_desktop {
      cursor: default;
      text-decoration: none;
    }
  }
  .sr-label_zaiko_status {
    background-color: #7f7fff;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    margin: 2px;
    padding: 5px 20px;
  }
  .sr-label_unused {
    background-color: #fff;
    border-radius: 3px;
    border: solid 2px;
    color: #84cbfa;
    display: inline-block;
    margin: 2px;
    padding: 5px 20px;
  }
}

.ec-productTitle {
  border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    padding: 10px 0;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 35px;
}

.sr-subTitle {
  border-bottom: 1px solid #333;
  padding: 0 0 10px;
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 65px;
}

.sr-shop {
  margin-top: 10px;
  p {
    display: inline-block;
    width: 100%;
    padding: 5px 0;
    margin: 0 0 4px 0;
    background: #e4d7ce;
    text-align: center;
    border-radius: 2px;
    font-size: 10px;
    font-weight: bold;

    @include media_desktop {
      width: calc(50% - 2px);
      padding: 6px 0;
      margin-bottom: 4px;
    }
    .sr-shop_1 { /* mahna */
      background-color: #eee2d6;
    }
    .sr-shop_2 { /* groove */
      background-color: #d8cec5;
    }
    .sr-shop_3 { /* collection */
      background-color: #f1e8e1;
    }
    .sr-shop_4 { /* accent */
      background-color: #ebebeb;
    }
    .sr-shop_5 { /* gold */
      background-color: #e4d7ce;
    }
    .sr-shop_6 { /* west */
      background-color: #e4e3df;
    }
    .sr-shop_7 { /* plus */
      background-color: #ff9999;
      color: #ffffff;
    }
  }  
}

.sr-peculiarity {
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px dotted #666;
  ul {
    list-style: none;
    padding-left: 0;
  }
}

.favorite {
  display: inline-block;
  font-size: 1rem;
  padding: 3px 0;
  @include media_desktop {
    font-size: 1.2rem;
  }
  i {
    font-size: 8px;
    font-weight: bold;
    left: 0px;
  }
  a {
    text-decoration: none;
    color: #333333;
  }
}

.star {
  display: inline-block;
  font-size: 20px;
  margin-left: 10px;
  color: #e35480;
}

.sr-additional_product_area {
  ul {
    margin-top: 20px;
    list-style: none;
    padding-inline-start: 0px;

    li {
      text-align: center;
      position: relative;

      img {
        display: table;
        border: solid 1px #eaeaea;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }
    p {
      text-align: left;
    }
  }
  ul::after {
    clear: both;
    content: '';
    display: block;
  }
  .sr-col4 {
    width: 23%;
    float: left;
    margin-right: 2%;
    margin-bottom: 25px;
    a {
      color: #333333;
    }
  }
  .sr-label_brand {
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .sr-apparel_shop, .sr-product_name, .sr-product_code, .sr-product_price, .sr-product-size {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .sr-label_zaiko_status {
    padding: 5px;
    @include media_desktop {
      padding: 5px 20px;
    }
  }
}

/* 商品詳細部分の商品タグ */
.productTag {
  button {
    border: 1px;
    line-height: 1em;
    display: inline-block;
    white-space: nowrap;
    padding: 8px 8px; /* 文字周りの余白 */
    margin-top: 3px; /* タグ同士の余白 */
    border-radius: 4px; /* 角を少し丸く */
    text-decoration: none;
    font-weight: normal;
    font-size: 12px;
    width: auto;
    height: auto;
    margin-bottom: 3px;
  }

  .addedTagSelected {
    cursor: not-allowed;
    background: #fafafa; /* 背景色 */
    color: #eee; /* 文字色 */
  }

  .addedTagUnselected {
    background: #f3f3f3; /* 背景色 */
    color: #444; /* 文字色 */
    &:hover {
      background: #787878; /* マウスホバー時の背景色 */
      color: #ffffff; /* マウスホバー時の文字色*/
    }
  }
}

.productTag {
  a {
    border: 1px;
    line-height: 1em;
    background: #f3f3f3; /* 背景色 */
    color: #444; /* 文字色 */
    display: inline-block;
    white-space: nowrap;
    padding: 8px 8px; /* 文字周りの余白 */
    margin-top: 3px; /* タグ同士の余白 */
    border-radius: 4px; /* 角を少し丸く */
    text-decoration: none;
    &:hover {
      background: #787878; /* マウスホバー時の背景色 */
      color: #ffffff; /* マウスホバー時の文字色*/
    }
  }
}