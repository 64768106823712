@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/btn";

/*
その他のボタン

通常のボタンや、アイコンボタン以外のボタンを定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.3
*/


/*
ページトップボタン

ページトップボタンを表示します

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
.ec-blockTopBtn

Styleguide 2.3.1
*/
.ec-blockTopBtn{
  display: none;
  position: fixed;
  width: 40px;
  height: 40px;
  right: 4px;
  bottom: 40px;
  cursor: pointer;
  color: #333;
  font-size: 25px;
  text-align: center;
  line-height: 40px;
  opacity: 1;
  background-color: transparent;
  @include media_desktop {
    right:30px;
    bottom: 30px;
  }
}
