@import "../mixins/media";
/*
会員登録

新規会員登録 に関する Project コンポーネントを定義します。

ex [新規会員登録画面　会員登録](http://demo3.ec-cube.net/entry)

Markup:
include /assets/tmpl/elements/19.1.register.pug
+ec-registerRole

Styleguide 19.1

*/
.ec-registerRole{
  @include container;
  p {
    font-size: 14px;
    line-height: 1.6;
  }
  a {
    color: #333;
    text-decoration: underline;
  }
  .ec-checkbox label > span {
    margin-right: 20px;
  }
  .ec-zipInputHelp > a > span {
    color: #333;
    text-decoration: underline;
  }
  .sr-agreement {
    text-align: center;
  }
  .sr-agreement label {
    color: #f00;
    font-size: 18px;
    font-weight: normal;
  }
  & &__actions {
    border-bottom: 1px dotted #ccc;
    padding-top:20px;
    padding-bottom: 20px;
    text-align: center;
    @include media_desktop {
      text-align: left;
    }
    p {
      margin-bottom: 16px;
    }
  }
  .sr-action.ec-off2Grid__cell {
    height: 120px;
    position: relative;
    @include media_desktop {
      position: static;
    }
  }
  .ec-off2Grid__cell .ec-blockBtn--cancel, .ec-off2Grid__cell .ec-blockBtn--action {
    @include media_desktop {
      width: 45%;
    }
  }
  .ec-blockBtn--cancel {
    position: absolute;
    bottom: 0;
    text-decoration: none;
    @include media_desktop {
      display: inline-block;
      margin: 2%;
      position: static;
    }
  }
  .ec-blockBtn--action {
    margin-bottom: 16px;
    @include media_desktop {
      display: inline-block;
      margin: 2%;
    }
  }
  a.ec-blockBtn--action {
    color: #fff;
    text-decoration: none;
  }
  a.ec-inlineBtn {
    text-decoration: none;
  }
  .c-form__fileUploadThumbnails img {
    max-width: 170px;
  }
}
.ec-registerCompleteRole {
  @include container;
  .sr-targetPath {
    margin: 20px 0;
  }
}
