.ec-barcode-box1 {
    border: 1px solid;
}
.ec-barcode-box2 {
    max-width: 480px; 
    text-align: center; 
    margin: 20px auto; 
}
.ec-barcode-box2 > svg {
    width: 90%;
}
.ec-barcode-font {
    font-size: 120%;
}
.ec-barcode-spacebox {
    height: 120px;
} 