@import "../mixins/media";
/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

ex [お問い合わせ](http://demo3.ec-cube.net/contact)

Markup:
include /assets/tmpl/elements/19.2.contact.pug
+ec-contactRole

Styleguide 19.2

*/
.ec-contactRole{
  @include container;
  padding: 0 20px;
  margin-bottom: 20px;
  @include media_desktop {
    padding: 0;
  }
  & &__actions {
    padding-top:20px;
  }
  p.ec-para-normal {
    font-size: 14px;
    line-height: 1.6;
    margin:16px 0;
    @include media_desktop {
      margin: 16px auto;
      width: 60%;
    }
  }
  p.sr-forgot-href, p.sr-calendar-href, p.sr-about-keep {
    color: #f00;
    display: none;
    a {
      color: #f00;
    }
  }
  .ec-blockBtn--cancel {
    position: absolute;
    bottom: 0;
    @include media_desktop {
      display: inline-block;
      margin: 2%;
      position: static;
      width: 45%;
    }
  }
  .ec-blockBtn--action {
    @include media_desktop {
      display: inline-block;
      margin: 2%;
      width: 45%;
    }
  }
}
.ec-ContactRole__actions .ec-off2Grid__cell {
  height: 120px;
  position: relative;
  @include media_desktop {
    position: static;
  }
}
.ec-contactConfirmRole {
  @include container;
  p {
    font-size: 14px;
  }
  & &__actions {
    padding-top:20px;
  }
  .ec-blockBtn--action {
    margin-bottom: 16px;
  }
  .ec-blockBtn--cancel {
    position: absolute;
    bottom: 0;
    @include media_desktop {
      display: inline-block;
      margin: 2%;
      position: static;
      width: 45%;
    }
  }
  .ec-blockBtn--action {
    @include media_desktop {
      display: inline-block;
      margin: 2%;
      width: 45%;
    }
  }
}
.ec-contactCompleteRole {
  @include container;
}
