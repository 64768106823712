@import "../mixins/media";

/*
標準セクション

通常のコンテナブロックです。

ex [商品詳細ページ　コンテナ](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-roleRole

Styleguide 11.1
*/
.ec-role{
  @include container;
}

.ec-history-role{
  @include container;
  text-align: center;
  width: 100%;

  .ec-inlineBlockBtn {
    display:inline-block; 
    @include btn-default;
  }
  @include media_desktop {
    width: 33.33333%;
    padding: 0 16px;
    .ec-inlineBlockBtn {
      display:inline-block;
      margin-top: 10px;
      width: 200px;
    }
  }   
}

/*
マイページセクション

マイページ専用のコンテナブロックです。

ex [マイページ　コンテナ](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-mypageRole

Styleguide 11.1.2
*/
.ec-mypageRole{
  @include mypageContainer;

  .ec-pageHeader h1{
    @include media_desktop {
      margin: 10px 0 48px;
      padding: 8px 0 18px;
    }
  }
}

.ec-mypageLoginRole, .ec-loginRole {
  @include container;

  h2 {
    font-size: 16px;
    font-weight: bold;
    margin: 32px 0 16px;
    padding: 20px 0;
    border-bottom: 1px dashed #a59881;
  }
  p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  label {
    font-weight: normal;
  }
  a {
    color: #9c8d72;
    text-decoration: underline;
  }
  a:hover {
    color: #9c8d72;
    transition: opacity 1s;
    opacity: 0.6;
    text-decoration: underline;
  }
  a:visited {
    color: #9c8d72;
  }
  @include media_desktop {
    .ec-login {
      padding-bottom: 30px;
    }
  }
  .ec-login .ec-login__input {
    margin-bottom: 0;
  }
  .ec-login__link {
    margin: 16px 0;
  }
  .ec-blockBtn--action {
    margin: 16px 0;
    font-weight: normal;
    @include media_desktop {
      font-size: 16px;
    }
  }
  .ec-blockBtn--cancel {
    margin: 16px 0;
    color: #333;
    font-weight: normal;
    text-decoration: none;
    @include media_desktop {
      font-size: 16px;
    }
  }
}

.ec-forgotRole {
  p {
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.ec-forgotCompleteRole {
  h2 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.sr-columnSearch {
  width: 250px;
  & &__block {
    margin: 10px 0;
  }
  & &__block img {
    width: 100%;
  }
  .ec-rectHeading {
    border-bottom: 1px solid #333;
    margin: 10px 0;
  }
  .ec-rectHeading > h2 {
    background-color: #fff;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
  .searchform .form-check {
    padding-left: 20px;
  }
  .checkbox input[type="checkbox"] {
    margin: 4px 0;
    position: relative;
  }
  .checkbox label {
    padding-left: 10px;
  }
  .sr-btn_clear {
    background-color: #ccc;
    color: #666;
    padding: 10px 5px;
    transition: opacity 1s;
  }
  .sr-btn_clear.sr-btn_inline {
    width: calc(30% - 2px);
  }
  .sr-btn_clear:hover {
    opacity: 0.6
  }
  .sr-btn_submit {
    background-color: #666;
    border: solid 1px #ccc;
    color: #fff;
    padding: 10px 5px;
    transition: opacity 1s;
  }
  .sr-btn_submit.sr-btn_inline {
    width: calc(70% - 4px);
  }
  .sr-btn_submit:hover {
    opacity: 0.6;
  }
  .sr-collapse_button {
    background-color: #fff;
    border: 0;
    font-weight: bold;
    text-align: left;
  }
  .sr-collapse_button:focus {
    outline: none;
  }
  .sr-collapse_button .fa-plus {
    display: none;
  }
  .sr-collapse_button .fa-minus {
    display: inline;
  }
  .sr-collapse_button.collapsed .fa-plus {
    display: inline;
  }
  .sr-collapse_button.collapsed .fa-minus {
    display: none;
  }
}

.sr-makerSelector {
  .modal-dialog {
    @include media_desktop {
      width: 1000px;
    }
  }
  .modal-title {
    font-size: 14px;
  }
  .modal-dialog .list-group-item {
    display: inline-block;
    margin: 4px;
  }
  .modal-dialog .list-group-item:hover {
    cursor: pointer;
  }
}

a.sr-btn {
  color: #333;
  text-decoration: underline;
  transition: opacity 1s;
}
a.sr-btn:hover {
  opacity: 0.6;
}

.sr-informationRole {
  font-size: 13px;
  margin: auto;
  padding-top: 20px;
  width: 80%;
  .sr-sub_header {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.1em;
    color: black;
    font-size: 1.4em;
    border-bottom: 1px solid #666;
    @include media_desktop {
      margin: 20px 40%;
    }
  }
  .ec-pageHeader h1 {
    border-bottom: none;
    color: #b22222;
  }
  p, ul {
    line-height: 1.8em;
    margin: 4% auto;
    padding-left: 0;
    text-align: left;
    @include media_desktop {
      font-size: 14px;
      text-align: center;
    }
  }
  ul {
    list-style-type: none;
  }
}